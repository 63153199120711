@import '~packages/styles/_zLayers';
@import '~app/fieldapp/_variables';

.timersDrawer {
  background: transparent;
  box-shadow: none;
  max-width: $breakpointSmall;
  padding: 0;
  // ensure this drawer is above all other drawers
  z-index: $zLayer5 + 1;

  @include screenSizeDesktop {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.timerDrawerWrapper {
  background-color: transparent;
  margin: auto;
  width: 100%;

  &.withRadius {
    border-radius: 24px 24px 0 0;
  }
}

.timersDrawerContent {
  background-color: white;
  box-shadow: 0 15px 38px -6px rgba(0, 51, 73, 0.2),
    0 15px 38px -6px rgba(44, 52, 57, 0.2);
}

@import '~packages/styles/_colors';
@import '../SuperModal.variables';

.drawer {
  z-index: $drawerZIndex;
}

.drawerBackdrop {
  z-index: $drawerBackdropZIndex;
}

.right {
  border-left: 1px solid $hk-midnight-20;
}

.left {
  border-right: 1px solid $hk-midnight-20;
}

.superModalContainer {
  padding: 0 24px 24px;

  .superModalText {
    font-size: 14px;
    max-width: 240px;
    padding-bottom: 24px;
  }

  .buttonsContainer {
    min-width: fit-content;
    margin: -20px;
  }
}

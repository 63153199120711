@import '~packages/styles/colors';

.bar {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: center;
  height: 32px;
  width: 100%;
}

.default {
  background-color: $hk-midnight-70;
  color: white;
}

.lake {
  background-color: $hk-lake;
  color: white;
}

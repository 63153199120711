@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.cleanDetail {
  background: white;
  font-size: 14px;
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 64px;
  position: relative;
}

.cleanDetailWrapper {
  margin: 24px 16px 24px 24px;
}

.amenityInfoSection {
  font-weight: 600;
}

.section {
  padding: 12px 0px;

  & + & {
    border-top: 1px solid $uiDividerColor;
  }
}

.lateCheckout {
  font-weight: bold;
}

.externalLink {
  color: $hk-lake;
  display: flex;
}

.accessCodeLabel {
  font-weight: 600;
}

/**
 * Address section styles
 */
.addressSection {
  margin-bottom: 12px;
  padding-top: 0;
  position: relative;
}

.addressInfoContainer {
  &.unitName {
    font-size: 16px;
    font-weight: 600;
  }
}

.copySection {
  background-color: #f6f6f6;
  font-size: 14px;
  margin: 4px 0;
  padding: 8px 16px;
  word-wrap: break-word;
}

.badge {
  position: absolute;
  right: 0;
}

.cleanB2BBadge {
  color: white;
  position: absolute;
  right: 0;
}

.cleanPotentialB2BBadge {
  background-color: white;
  border: 1px $hk-lake solid;
  color: $hk-lake;
  position: absolute;
  right: 0;
}

/**
 * Date section styles
 */
.dateInfoSection {
  display: flex;
  flex-direction: row;
}

.dateInfoContainer {
  display: flex;
  flex-direction: column;
  width: 50%;

  &.dueDate {
    border-left: 1px solid $uiDividerColor;
    padding-left: 16px;
  }
}

/**
 * Unit section styles
 */
.cleanUnitInfoContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;

  .cleanDetailIcon {
    padding-top: 4px;
  }

  .details {
    padding-left: 8px;
    width: 100%;
  }

  &:first-of-type {
    padding-top: 12px;
  }
}

.createTicketLabel {
  margin: 0;
}

.wifiNetworkText {
  word-wrap: break-word;
}

.hkNote {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.unitManagerInfoSection {
  padding-bottom: 24px;
  padding-top: 24px;
}

.unitManagerHeader {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.unitManagerInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.unitManagerPhone {
  color: $hk-lake;
}

@import '~packages/styles/_colors';

$smHeight: 32px;
$mdHeight: 40px;
$lgHeight: 48px;
$xlHeight: 56px;

$inputIconTextOffset: 36px;

.inputContainer + .inputContainer {
  margin-top: 16px;
}

.inputLabel {
  color: $hk-midnight;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.inputWrapper {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.fullWidth {
  width: 100%;
}

// getting these styles to apply requires specifying the input type
.input[type='email'],
.input[type='text'],
.input[type='password'],
.input[type='search'] {
  border-radius: 4px;
  border: 1px solid $hk-midnight-30;
  color: $hk-dusk;
  display: inline-block;
  font-weight: 500;
  height: $mdHeight;
  line-height: 24px;
  outline: none;
  padding: 0 16px;
  width: 100%;

  &.error {
    border: 2px solid $hk-alertness;
  }

  &:focus {
    outline: 4px auto $hk-lake;
    outline-offset: -2px;
  }

  &::placeholder {
    color: $hk-dusk-lightest;
  }

  &:disabled {
    background: #f2f2f2;
  }

  &.withLeftIcon {
    padding-left: $inputIconTextOffset;
  }

  &.withRightIcon {
    padding-right: $inputIconTextOffset;
  }
}

.inputIcon {
  margin: 0 8px;
  position: absolute;

  &.leftIcon {
    left: 0;
  }

  &.rightIcon {
    right: 0;
  }

  &.count {
    color: $hk-dusk-lighter;
    font-size: 12px;
    line-height: 16px;

    &:not(:last-child) {
      right: 24px;
    }
  }
}

.errorMessage {
  color: $hk-alertness;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

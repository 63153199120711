/*
 Pre-defined values for z-index settings. They are spaced out to allow for additional
 ad-hoc values to be defined in between, but as often as possible, we should try to
 stick to the 5 pre-defined layers.
 */

// $zLayerBase of 0 is defined for readability's sake, although you will probably
// never actually need to use it, since 0 is already the default.
$zLayerBase: 0;

$zLayer1: 10;
$zLayer2: 20;
$zLayer3: 30;
$zLayer4: 40;
$zLayer5: 500;

// ideally, we should not define any z-layers higher than this value
// if you REALLY need a higher value for any reason, we probably just need to bump this one up instead
$zLayerMax: 1050;

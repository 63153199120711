@import '~packages/styles/_colors';

.dataTableRow {
  align-items: center;
  background: white;
  border-bottom: 1px solid #e0e9ed;
  display: flex;
  padding: 16px 24px;
  position: relative;
}

.hoverable {
  &:hover {
    background: $hk-midnight-2;

    .showOnHover {
      visibility: visible;
    }
  }
}

.disabled {
  background: #f3f3f4;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-items: flex-start;

  & + & {
    margin-left: 24px;
  }

  .showOnHover {
    visibility: hidden;
  }
}

.actionColumn {
  display: flex;
  flex-direction: row;
  flex: 0 1 10%;
  justify-content: flex-end;
}

.actionLink {
  & + & {
    margin-left: 16px;
  }
}

/* Padding fix for link buttons to retain span look*/
.removePadding {
  padding: 0;
}

.rating {
  text-align: left;
  font-style: normal;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}
.rating::before {
  display: block;
  content: var(--stars, '★★★★★');
  color: #eee;
}
.rating::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: var(--stars, '★★★★★');
  width: 0;
  color: #ff8c00;
  overflow: hidden;
  height: 100%;
  width: var(--rating-width, 0%);
}

@import '~packages/styles/_colors';
@import '~packages/styles/_mixins';

.container {
  border-top: 1px solid $hk-midnight-20;
  display: flex;
  flex-direction: column;
  // the below two styles ensure that even if there more timers than fit on the screen, we can submit them
  max-height: 80vh;
  overflow: scroll;

  .header {
    align-items: center;
    border-bottom: 1px solid $hk-midnight-20;
    display: flex;
    flex-direction: row;
    height: 71px;

    &.postStayCleanTimer {
      border-left: 8px solid $hk-lake-20;
    }

    &.deepCleanTimer {
      border-left: 8px solid #e6e3d6;
    }

    &.ticketTimer {
      border-left: 8px solid $hk-success-40;
    }

    &.visitTimer {
      border-left: 8px solid #e2e8e8; // tetons30 in JSS colors
    }

    .headerText {
      padding-left: 16px;

      &.otherTimer {
        padding-left: 24px;
      }

      .headerLabel {
        font-weight: bold;
        font-size: 14px;
      }

      .cleanInfo {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 8px;

        .unitInfo {
          @include truncateTextWithEllipsis();
          padding-right: 16px;
        }
      }
    }
  }

  .timerDisplaySection {
    padding-bottom: 24px;

    .timerRow {
      display: grid;
      column-gap: 4px;
      grid-template-columns: min-content minmax(auto, 50%) minmax(auto, 50%);
      grid-template-areas:
        'n a b'
        'x c d';
      padding: 24px 24px 0 24px;

      @media (max-width: 480px) {
        grid-template-columns: min-content minmax(auto, 100%);
        grid-template-areas:
          'x n'
          'x a'
          'x c'
          'x b'
          'x d';
        padding: 0px 24px 24px 24px;
      }

      .closeButtonContainer {
        grid-area: x;
        @include centerWithFlex();
        @media (max-width: 480px) {
          margin-bottom: 48px;
        }

        .closeButton {
          background-color: $hk-alertness-10;
          border-radius: 4px;
          cursor: pointer;
          height: 24px;
          width: 24px;

          @include centerWithFlex();

          .closeX {
            color: $hk-alertness;
          }
        }
      }

      .stopLabel {
        grid-area: b;
      }

      .startLabel {
        grid-area: a;
      }

      .startDisplay {
        grid-area: c;
      }

      .stopDisplay {
        grid-area: d;
      }

      .timerLabel {
        align-items: center;
        display: flex;
        flex-direction: row;

        @media (max-width: 480px) {
          margin-top: 32px;
        }

        .labelText {
          font-size: 12px;
          padding-left: 4px;
        }
      }

      .timerDisplay {
        background-color: $hk-dusk-8;
        border-radius: 2px;
        padding: 8px;
      }

      .timerContainer {
        position: relative;
      }

      .timerInput {
        border-radius: 2px;
        border: 1px solid $hk-midnight-30;
        padding: 8px;
        background: #ffffff;

        &.error {
          border: 2px solid $hk-alertness;
        }
      }

      .timerInputHide {
        height: 0;
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .timerInputErrorMessage {
        position: absolute;
        color: $hk-alertness;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        padding-top: 2px;
        padding-left: 2px;
      }
    }
  }

  .textInputs {
    border-top: 1px solid $hk-midnight-20;
    padding: 16px 24px;

    .inputWrapper {
      max-width: 80px;
    }
  }

  .buttonsSection {
    border-top: 1px solid $hk-midnight-20;
  }

  .buttons {
    align-items: center;
    background-color: $hk-midnight-2;
    display: flex;
    justify-content: space-evenly;
    padding: 8px;

    .button {
      flex-grow: 1;
    }
  }
}

.mileageNotice {
  font-weight: 600;
  margin-top: 8px;
}

.offlineSaveNote {
  @include centerWithFlex();

  font-size: 12px;
  line-height: 16px;
  margin: 16px;
  margin-bottom: 24px;
  text-align: center;
}

.triangle {
  border-bottom: 6px solid transparent;
  border-left: 8px solid black;
  border-top: 6px solid transparent;
  height: 0;
  width: 0;
}

.square {
  background-color: black;
  border-radius: 2px;
  height: 10px;
  width: 10px;
}

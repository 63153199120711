@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.fieldAppViewWrapper {
  background: $hk-midnight-4;
  margin: auto;
  max-width: $breakpointSmall;
  min-height: 100vh;

  @include screenSizeDesktop {
    border: 1px solid $hk-midnight-30;
    box-shadow: 0 1px 4px 0 rgba(216, 216, 216, 0.5);
  }
}

$hk-dusk: #2c3439;
$hk-dusk-light: #4c5357;
$hk-dusk-lighter: #707579;
$hk-dusk-lightest: #969a9c;
$hk-dusk-8: #efeff0;

$hk-white: #ffffff;

$hk-midnight: #003349;
$hk-midnight-90: #1a475b;
$hk-midnight-80: #335c6d;
$hk-midnight-70: #4d7080;
$hk-midnight-60: #668592;
$hk-midnight-50: #8099a4;
$hk-midnight-40: #99adb6;
$hk-midnight-30: #b2c2c8;
$hk-midnight-20: #ccd6db;
$hk-midnight-10: #e5ebed;
$hk-midnight-4: #f5f7f8;
$hk-midnight-2: #fbfbfc;

$hk-lake: #0078ab;
$hk-lake-40: #7fbbd5;
$hk-lake-20: #cce5f4;
$hk-lake-10: #e5f2f9;

$hk-success: #6ba342;
$hk-success-40: #c4dab3;

$hk-alertness: #d62e4f;
$hk-alertness-10: #fcebeb;
$hk-alertness-40: #efabb9;

$hk-awareness-40: #edd28f;

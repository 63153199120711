@import './_colors';

@mixin wrappedLink {
  $linkColor: $hk-midnight;

  a {
    color: $linkColor;
  }

  a:hover {
    color: $linkColor;
    text-decoration: none;
  }
}

// specify that a container should constrain its max width and center itself
@mixin constrainAndCenter($width: 1440px) {
  margin: auto;
  max-width: $width;
}

@mixin pageContentArea {
  margin: 0 40px 72px 40px;
}

@mixin truncateTextWithEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin centerWithFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

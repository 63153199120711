@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.ticketCard {
  cursor: pointer;
  margin-bottom: 8px;
}

.overdueIcon {
  background: white;
  border-radius: 36px;
  border: 1px solid $hk-dusk;
  color: $hk-alertness;
  padding: 2px 16px;
}

.statusBar {
  background: $hk-midnight-10;
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.aware {
  background: $hk-awareness-40;
}

.urgent {
  background: $hk-alertness-40;
}

.severity {
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.infoContainer {
  padding: 10px 16px 20px;
}

.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.code {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 8px;
}

.codeContainer {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.address {
  font-size: 12px;
  line-height: 16px;
  color: $hk-dusk-light;
}

.addTicketButton {
  color: $hk-lake;
  display: flex !important;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  justify-content: flex-end;
  line-height: 20px;
  margin-left: auto;
  margin-right: 16px;
}

.alreadyAddedToVisit {
  color: $hk-dusk-lighter;
  display: flex !important;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  height: 20px;
  justify-content: flex-end;
  line-height: 20px;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: 16px;
}

.coverageIcon {
  color: $hk-alertness;
}

.ownerCommentBadgeContainer {
  margin-bottom: 8px;
}

@import '~packages/styles/_colors';
@import '~packages/styles/_shadows';
@import '~packages/styles/_zLayers';

.superModal {
  background: white;
  box-shadow: $shadow4;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: fixed;
  width: fit-content;
  z-index: $zLayer5;
}

.superModalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.superModalTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  padding-left: 24px;
}

.closeIconWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .closeIcon {
    color: $hk-midnight-40;
  }
}

.superModalBackdrop {
  background-color: rgba(7, 7, 7, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zLayer4;
}

@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.linkCard {
  padding: 16px;
}

.divider {
  border-color: $uiDividerColor;
  margin: 0;

  &:last-child {
    display: none;
  }
}

.link {
  color: $hk-lake;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;

  &:focus {
    outline: 1px solid $hk-midnight-30;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  // blanket selector to ensure all child elements are Flex containers
  span {
    display: flex;
  }
}

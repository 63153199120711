@import '~packages/styles/_colors';

.pausedTimersContent {
  max-height: 80vh;
  overflow: hidden;
}

.pausedTimersHeader {
  align-items: center;
  border-bottom: 1px solid $hk-midnight-20;
  border-top: 1px solid $hk-midnight-20;
  display: flex;
  font-weight: bold;
  height: 58px;
  line-height: 34px;
  justify-content: space-between;
  padding: 0 16px;

  .pausedText {
    font-size: 12px;
    text-transform: uppercase;
  }

  .showMoreText {
    color: $hk-lake;
    font-size: 13px;
    opacity: 0;
    transition: opacity 300ms ease;
    user-select: none;

    &.visible {
      cursor: pointer;
      opacity: 1;
    }
  }
}

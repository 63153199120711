@import '~packages/styles/_colors';

.content {
  color: #2c3439;
  display: flex;
  padding: 0 1.5rem 0;
}

.contentWrapper {
  flex-grow: 1;
  margin: 62px auto 0;
  max-width: 600px;
}

.contentTitle {
  font-size: 1.35rem;
}

.detail {
  margin-top: 16px;
}

.reload {
  color: $hk-lake;
  margin-top: 32px;
}

@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.profileHeader {
  align-items: center;
  border-bottom: 1px solid $uiDividerColor;
  display: flex;
  font-size: 15px;
  height: 40px;
  justify-content: space-between;
  padding: 10px 8px;
}

.userName {
  font-weight: 600;
}

@import '~packages/styles/_colors';

$animation-time: 150ms;

.switch {
  display: inline-block;
  flex-shrink: 0;
  height: 32px;
  width: 56px;
}

.well {
  background-color: $hk-midnight-20;
  border-radius: 16px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 100%;
  padding: 2px;
  position: relative;
  transition: background-color $animation-time ease-in;
}

.tab {
  align-items: center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  height: 28px;
  justify-content: center;
  left: 0;
  position: relative;
  transition: left $animation-time ease-in;
  width: 28px;
}

.inputElement {
  // Hide the input visually but leave visible to screen readers
  clip: rect(0 0 0 0);
  position: absolute;
}

.checked {
  .well {
    background: $hk-lake;
  }

  .tab {
    left: 24px;
  }
}

.disabled {
  .well {
    background-color: rgba(229, 235, 237, 1);
    cursor: not-allowed;
  }
}

.focused {
  .well {
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 0 4px $hk-lake-40;
  }
}

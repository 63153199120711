@import '~packages/styles/_colors';

.tab {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  text-align: center;
  user-select: none;

  &.default {
    color: $hk-midnight-70;
    padding: 16px;
  }

  &.lake {
    color: $hk-midnight-70;
    padding: 16px;
  }

  &.floating {
    border-radius: 20px;
    color: $hk-midnight;
    margin-right: 32px;
    padding: 8px 16px;
  }

  &:hover > .hoverBorder {
    &.default,
    &.lake {
      $height: 4px;

      background: #d6e0e5;
      height: $height;
      left: 0;
      position: absolute;
      top: calc(100% - #{$height} + 1px);
      width: 100%;
    }
  }

  &:hover {
    &.floating {
      background-color: $hk-midnight-2;
    }
  }
}
.active {
  &.default {
    color: $hk-midnight;
  }

  &.lake {
    color: $hk-lake;
  }

  &.floating {
    background-color: $hk-midnight-4;

    &:hover {
      background-color: $hk-midnight-4;
    }
  }
}

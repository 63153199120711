@import '~packages/styles/_zLayers';

.buttonGroup {
  display: inline-flex;
  position: relative;
  vertical-align: middle;

  & > button {
    position: relative;
  }

  & > button:focus {
    z-index: $zLayer1;
  }

  & > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
}

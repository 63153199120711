.body {
  padding: 0 !important;
}

.progress {
  background: #003349 !important; /* midnight 100 */
  bottom: unset !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
}

.toast {
  border-radius: 0 !important;
  min-height: unset !important;
  padding: 0 !important;
}

@import '~packages/styles/_colors';

.badge {
  background-color: $hk-lake;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  padding: 1px 4px;
  user-select: none;
  white-space: nowrap;
}

@import '~packages/styles/_colors';

.lastFetchBanner {
  color: $hk-dusk-lighter;
  font-size: 12px;

  .updateLink {
    color: $hk-lake;
    cursor: pointer;
    margin-left: 8px;
  }
}

@import '~packages/styles/_colors';
@import '~packages/styles/_shadows';
@import '~packages/styles/_zLayers';
@import '~app/fieldapp/_variables';

.banner {
  background-color: $hk-alertness;
  box-shadow: $shadow4;
  color: #fff;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: all 300ms ease;
  width: 100vw;
  z-index: $zLayer5 + 1;
}

.bannerContainer {
  display: flex;
  margin: 0 auto;
  max-width: 768px;

  @include screenSizeMobile {
    flex-direction: row-reverse;
  }
}

.bannerContent {
  display: flex;
  justify-content: center;
  padding: 16px 16px 0px 16px;
  width: 100%;

  @include screenSizeMobile {
    flex-direction: column;
    width: 90%;
  }
}

.bannerLink {
  color: inherit;
  text-decoration: underline;
}

.closeIconWrapper {
  position: absolute;
  right: 8px;
  top: 8px;

  @include screenSizeMobile {
    display: flex;
    justify-content: flex-end;
    width: 10%;
    padding-top: 8px;
    position: relative;
    right: 0;
    top: 0;
  }

  .closeIcon {
    color: #fff;
  }
}

.bannerText {
  font-size: 16px;
  font-weight: 900;
}

.showing {
  transform: translateY(0);
}

@import '~app/fieldapp/_variables';

.profilePageContent {
  font-size: 14px;
  font-weight: 600;
  padding: 24px 16px;
}

.profilePageSection {
  & + & {
    margin-top: 24px;
  }
}

.profilePageCard {
  padding: 16px;

  .divider {
    border-color: $uiDividerColor;
    margin: 0;
  }
}

.profilePageHeader {
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 24px;
  }
}

.profilePageAlert {
  margin-top: 12px;
}

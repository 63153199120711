@import '~packages/styles/_colors';

$contentTitleMargin: 16px;
$contentSectionMargin: 32px;
$defaultModalMaxWidth: 500px;
$defaultZIndex: 1050;
$modalPositionTop: 40px;

.modalBackdrop {
  background-color: rgba(7, 7, 7, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;

  &.hidden,
  &.enterStart,
  &.exitActive {
    opacity: 0;
  }

  &.enterActive,
  &.idle,
  &.exitStart {
    opacity: 1;
  }
}

.modalWrapper {
  bottom: $modalPositionTop;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}

.modalBody {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  margin: auto;
  max-height: calc(100% - 40px);
  max-width: $defaultModalMaxWidth;
  outline: none;
  overflow: auto;
  pointer-events: auto;
  position: relative;
  transform: translateY(0);

  &.enterActive,
  &.idle,
  &.exitStart {
    transform: translateY($modalPositionTop);
  }

  &.hidden,
  &.enterStart,
  &.exitActive {
    transform: translateY(0);
  }
}

.hidden {
  visibility: hidden;
}

.closeIconWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  position: absolute;
  width: 100%;
}

.closeIcon {
  color: $hk-midnight-40;
}

.modalContent {
  margin: $modalPositionTop;
}

.icon {
  color: $hk-midnight;
}

.contentWrapper {
  color: $hk-midnight-70;
  font-weight: 600;
}

.title {
  color: $hk-midnight;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: $contentTitleMargin;
}

.buttonsWrapper {
  display: flex;
  margin-top: $contentSectionMargin;
  width: 100%;

  &:not(.leftAlignedButtons) {
    justify-content: flex-end;
  }

  &.leftAlignedButtons {
    justify-content: flex-start;
  }
}

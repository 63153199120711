@import '~packages/styles/_colors';

.coveragePartnerSelector {
  :global(.asyncSelector__control) {
    background: transparent;
    border: none;
    border-radius: 2px;
  }

  :global(.coveragePartnerSelector__control--is-focused) {
    background-color: white;
    box-shadow: 0 0 0 1px $hk-midnight-40;

    :global(.coveragePartnerSelector__single-value) {
      opacity: 0.5;
    }
  }

  :global(.coveragePartnerSelector__single-value) {
    right: 0;
  }

  :global(.coveragePartnerSelector__indicator-separator) {
    display: none;
  }

  :global(.coveragePartnerSelector__menu) {
    border-radius: 1px;
  }

  :global(.coveragePartnerSelector__option) {
    color: inherit;
  }

  :global(.coveragePartnerSelector__option--is-focused) {
    color: inherit;
  }

  :global(.coveragePartnerSelector__option--is-selected) {
    background-color: $hk-midnight-10;
  }
}

@import '~packages/styles/_colors';

.cleanTimerControls {
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 12px 24px 12px 8px;
  position: absolute;
  right: 0;
}

.startTimerIcon {
  padding-left: 2px;
}

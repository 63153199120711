@import '~packages/styles/_colors';
@import '~packages/styles/_shadows';
@import '~app/fieldapp/_variables';

.navTabWrapper {
  background: white;
  box-shadow: $shadow1;
  display: flex;
  height: $navTabsHeight;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $zIndexFaNavTabs;
}

.navTab {
  align-items: center;
  color: $hk-midnight-50;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  transition: color 100ms ease-out;
  width: 50%;

  &.current {
    color: $hk-midnight;
    border-bottom: 3px solid $hk-lake;
    background: $hk-midnight-4;
  }

  & + & {
    border-left: 1px solid $hk-midnight-20;
  }
}

@import '~packages/styles/_colors';
@import '~app/fieldapp/_variables';

.header {
  padding: 14px;
  border-bottom: 1px solid $hk-midnight-20;

  h1 {
    color: $hk-dusk;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    text-transform: capitalize;
  }
}

.lastFetchBanner {
  margin: 16px;
  margin-bottom: 0;
}

.main {
  padding: 20px 8px;
}

.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: $hk-dusk;
  line-height: 24px;
  padding: 26px;
}

.noResultsIcon {
  margin-bottom: 16px;
  color: $hk-midnight-60;
}

.linksCard {
  margin-top: 24px;
}

@import '~packages/styles/_zLayers';

.updateNotifier {
  cursor: pointer;
  font-weight: bold;
  height: auto;
  line-height: 16px;
  min-height: 32px;
  padding: 4px 8px;
  position: relative;
  z-index: $zLayerMax - 1;
}

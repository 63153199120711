@import '~packages/styles/_colors';

$reactSelectDefaultText: $hk-midnight-80;
$reactSelectOptionsText: rgba(4, 6, 7, 0.8);

.fullWidth {
  width: 100%;
}

.select {
  :global(.selectWrapper__value-container) {
    padding: 0 16px;
  }

  :global(.selectWrapper__single-value) {
    margin: 0;
  }

  :global(.selectWrapper__control--is-focused) {
    &:hover {
      border-color: $hk-midnight-30;
    }
  }

  :global(.selectWrapper__indicator-separator) {
    display: none;
  }

  :global(.selectWrapper__dropdown-indicator) {
    color: $hk-midnight-80;
  }

  :global(.selectWrapper__menu) {
    border-radius: 1px;
  }

  :global(.selectWrapper__option) {
    color: inherit;
  }

  :global(.selectWrapper__option--is-selected) {
    background-color: $hk-midnight-10;
  }
}

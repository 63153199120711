@import '~packages/styles/_colors';

$animation-time: 150ms;

.checked {
  .well {
    background: $hk-midnight-4;
  }

  .tab {
    background: $hk-lake;
    left: 40px;
  }

  .external {
    color: $hk-midnight-2;
  }

  .internal {
    color: $hk-dusk-lightest;
  }
}

.disabled {
  .well {
    background-color: rgba(229, 235, 237, 1);
    cursor: not-allowed;
  }
}

.external {
  color: $hk-dusk-lightest;
  left: 48px;
  top: 7px;
  z-index: 1;
}

.focused {
  .well {
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 0 4px $hk-lake-40;
  }
}

.label {
  color: $hk-dusk;
  display: inline-block;
  font-size: 8px;
  font-weight: 800;
  letter-spacing: 1.5px;
  line-height: 12px;
  margin-left: 16px;
  text-transform: uppercase;
}

.inputElement {
  // Hide the input visually but leave visible to screen readers
  clip: rect(0 0 0 0);
  position: absolute;
}

.internal {
  color: $hk-midnight-2;
  left: 16px;
  top: 7px;
  z-index: 1;
}

.switch {
  display: inline-block;
  flex-shrink: 0;
  height: 32px;
  width: 90px;
}

.tab {
  align-items: center;
  background-color: $hk-lake;
  border-radius: 100px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  height: 28px;
  justify-content: center;
  left: 0;
  position: relative;
  transition: left $animation-time ease-in;
  width: 45px;
}

.toggleIcon {
  position: absolute;
}

.toggleContainer {
  background: #f9f9f9;
  border-bottom: 1px solid #ccd6db;
  border-left: 1px solid #ccd6db;
  border-radius: 0 0 4px 4px;
  border-right: 1px solid #ccd6db;
  display: flex;
  flex-grow: 1;
}

.toggleInnerContainer {
  align-items: center;
  background: #f9f9f9;
  display: flex;
  padding: 0 8px;
}

.well {
  background-color: $hk-midnight-4;
  border-radius: 16px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 100%;
  padding: 2px;
  position: relative;
  transition: background-color $animation-time ease-in;
}

@import '~packages/styles/_colors';

.notificationBadge {
  align-items: center;
  background-color: $hk-midnight;
  border-radius: 10.5px;
  color: white;
  display: flex;
  font-size: 14px;
  height: 21px;
  justify-content: center;
  line-height: 21px;
}

.single {
  width: 23px;
}

.double {
  width: 29px;
}

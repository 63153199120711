@import '~packages/styles/_colors';

.dataTableHeader {
  align-items: center;
  background-color: $hk-midnight-4;
  border-bottom: 1px solid #e0e9ed;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  padding: 16px 24px;
}

.column {
  flex: 1 1 0;
  margin-right: 24px;

  &:last-of-type {
    margin-right: 0;
  }
}

.actionColumn {
  flex: 0 1 10%;
}

@import '~packages/styles/_colors';
@import '~packages/styles/_zLayers';
@import '~app/fieldapp/_variables';

$baseFontSize: 14px;
$navColumnWidth: 68px;

$defaultSpacing: 32px;
$startOfMonthBucketSpacing: 16px;
$todaySpacing: 8px;

.cleansTimeline {
  padding-bottom: 12px;
}

.monthHeader {
  align-items: center;
  background-color: $hk-midnight-4;
  border-bottom: 1px solid $uiDividerColor;
  color: $hk-midnight-90;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  grid-column: 1 / 3;
  height: 48px;
  justify-content: space-between;
  line-height: 24px;
  margin: 0;
  padding-left: 16px;
  padding-right: 8px;
  position: sticky;
  z-index: $zLayer2;
  top: 40px;
}

.dayBucketWrapper {
  display: grid;
  grid-template-columns: $navColumnWidth auto;

  &.freezeWindowEnd {
    .timelineData {
      padding-bottom: $defaultSpacing;
    }
  }
}

@mixin navColumn {
  border-right: 2px solid $uiDividerColor;
  grid-column: 1;
  margin-right: 16px;
}

.timelineNav {
  @include navColumn;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  position: relative;
}

.lastFetchSpacer {
  @include navColumn;
}

.lastFetchHeader {
  grid-column: 2 / 3;
  padding-top: 8px;
  margin: 10px 20px;
}

.timelineData {
  grid-column: 2;
  margin-right: 8px;
  padding-top: $defaultSpacing;

  &.startOfMonthBucket:not(.today) {
    padding-top: $startOfMonthBucketSpacing;
  }

  &.today {
    padding-top: $todaySpacing;
  }
}

.noCleans {
  color: $hk-midnight-90;
  font-size: $baseFontSize;
  font-weight: 600;
  margin-top: -5px;
  padding-left: 8px;
}

@mixin dateDisplay {
  color: $hk-dusk;
  font-weight: bold;

  &.today {
    color: $hk-lake;
  }
}

.dayOfWeek {
  @include dateDisplay;
  font-size: $baseFontSize;
  line-height: 18px;
  padding-top: $defaultSpacing;

  &.startOfMonthBucket:not(.today) {
    padding-top: $startOfMonthBucketSpacing;
  }

  &.today {
    padding-top: $todaySpacing;
  }
}

.dayOfMonth {
  @include dateDisplay;
  font-size: 18px;
  line-height: 24px;
}

.freezeWindowBanner {
  background: linear-gradient(270deg, #335c6c 0%, #003349 100%);
  color: white;
  display: flex;
  font-size: $baseFontSize;
  font-weight: 600;
  grid-column: 1 / 3;
  justify-content: center;
  margin: 0;
  padding: 8px 16px;
  z-index: $zLayer1;
}

// the "circle icon" next to the date display for each day
// the marker for today is slightly larger, and has an actual box shadow
@mixin dayMarker($size) {
  border-radius: 100%;
  height: $size;
  position: absolute;
  // center the marker horizontally over the timeline edge
  right: $size * -0.5 - 0.5;
  top: $defaultSpacing;
  width: $size;

  &.startOfMonthBucket:not(.todayDayMarker) {
    top: $startOfMonthBucketSpacing;
  }

  &.todayDayMarker {
    top: $todaySpacing;
  }
}

.todayDayMarker {
  @include dayMarker(12px);
  background-color: $hk-lake;
  box-shadow: 0 0 5px 1px $hk-lake;
}

.notTodayDayMarker {
  @include dayMarker(10px);
  background-color: $hk-midnight-40;
  box-shadow: 0 0 1px 2.5px $hk-midnight-4;
}

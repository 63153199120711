@import '~packages/styles/_zLayers';

@mixin loaderFlexWrapper {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}

// reduce opacity for the individual "dots" in the Beat loader
.beat > span {
  opacity: 0.8;
}

.beat,
.clip {
  @include loaderFlexWrapper;

  justify-content: center;
  left: 0;
  top: 0;
}

.backdrop {
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: $zLayer1;

  &.transparent {
    opacity: 0;
  }

  &:not(.transparent) {
    opacity: 0.6;
  }
}

.loader {
  @include loaderFlexWrapper;

  margin: 0 auto;
  z-index: $zLayer1;

  &.centerAligned {
    justify-content: center;
  }

  &.leftAligned {
    justify-content: flex-start;
  }

  &.rightAligned {
    justify-content: flex-end;
  }
}

.icon {
  display: flex;

  &.centered {
    align-items: center;
  }

  &:not(.centered) {
    align-items: baseline;
  }

  & + & {
    margin-left: 0.5rem;
  }
}

.clickable {
  cursor: pointer;
  font-weight: 600;
}

.iconTextRight {
  flex-direction: row;

  .text {
    margin-left: 0.25rem;
  }
}

.iconTextLeft {
  flex-direction: row-reverse;

  .text {
    margin-right: 0.25rem;
  }
}

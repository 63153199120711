@import '~packages/styles/_colors';
@import '~packages/styles/_zLayers';

$navTabsHeight: 40px;
$uiDividerColor: #dfe5e8;

/**********************************************************
 * z-index configuration
 *********************************************************/
$zIndexFaAdminPanel: $zLayer4;
$zIndexFaNavTabs: $zIndexFaAdminPanel + 1;

/**********************************************************
 * Responsive UI configuration
 *********************************************************/
$breakpointSmall: 768px;

@mixin screenSizeMobile {
  @media only screen and (max-width: $breakpointSmall) {
    @content;
  }
}

@mixin screenSizeDesktop {
  @media only screen and (min-width: $breakpointSmall + 1) {
    @content;
  }
}

@import '~packages/styles/_colors';
@import '~packages/styles/_mixins';

// this is the exact size required for the two buttons in this container,
// and will ensure that the buttons do not re-flow on smaller sizes,
// but rather force the "unit name" text to be truncated instead
$buttonsSize: 94px;
$timerDetailPadding: 16px;
$closeButtonLength: 24px;

.timerDetailWrapper {
  padding: 0 16px;
  max-width: calc(
    100% - #{$buttonsSize} - #{$timerDetailPadding} - #{$closeButtonLength}
  );
}

.timerControls {
  margin-left: auto;
  min-width: $buttonsSize;
  width: $buttonsSize;
}

.closeButton {
  background-color: $hk-alertness-10;
  border-radius: 4px;
  cursor: pointer;
  height: $closeButtonLength;
  margin-left: 8px;
  width: $closeButtonLength;

  @include centerWithFlex();

  .closeX {
    color: $hk-alertness;
  }
}

@import '~packages/styles/_colors';

.tabbedContentWrapper {
  display: flex;
  margin-bottom: 24px;
  position: relative;

  &.default {
    border-bottom: 1px solid $hk-midnight-20;
  }
}

.tabbedContent {
  display: flex;
}

.tabWrapper {
  position: relative;
}

.activeTab {
  &.default,
  &.lake {
    $height: 4px;

    height: $height;
    left: 0;
    position: absolute;
    top: calc(100% - #{$height} + 1px);
    width: 100%;
  }

  &.default {
    background: $hk-midnight-70;
  }

  &.lake {
    background: $hk-lake;
  }
}
